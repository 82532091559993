import React, { useEffect, useState } from 'react'
import { Heading, Box, VStack } from '@chakra-ui/react'
import FieldArray from '../../../../molecules/controls/FieldArray'
import { useFormContext, useFieldArray } from 'react-hook-form'
import useFormState from '../../../../../hooks/useFormState'
import Card from '../../../../atoms/Card'
import { updateRecipients } from '../../../../../../reducers/forms/start-a-vidday'
import { useDispatch } from 'react-redux'
import { logCustomEvent } from '@vidday/utils'
import CheckExMark from '../../../../atoms/CheckExMark'
// import ControlledCheckbox from '../../../../molecules/controls/Checkbox'

const StepTwo = () => {
	const dispatch = useDispatch()

	/** Log tracking event for the form progress (STEP 2) */
	const [trackingSent, setTrackingSent] = useState(false)

	const {
		data: { recipients, isPersonal },
	} = useFormState('NewVidday')

	const { control, errors, watch, unregister } = useFormContext()

	const { fields, append, remove, insert } = useFieldArray({
		control,
		name: 'recipients',
		keyName: 'key',
	})

	/** Retrieve form's values */
	const values = watch()

	// useEffect(()=>{
	// 	if(isPersonal !== values.isPersonal) {
	// 		dispatch(updateIsPersonal(values.isPersonal))
	// 	}
	// },[values.isPersonal])

	useEffect(() => {
		/**
		 * Prepopulate form values for this step, based on existing values (wizzard form)
		 * or create a new entry if we don't have one already
		 * */
		if (values.recipients?.length && values.recipients[0]?.firstName && values.recipients[0]?.lastName) {
			if (JSON.stringify(recipients) !== JSON.stringify(values.recipients)) {
				dispatch(updateRecipients(recipients))
			}
		}

		/** Log tracking event for the form progress */
		if (
			!trackingSent &&
			values.recipients?.length &&
			values.recipients[0]?.firstName &&
			values.recipients[0]?.lastName
		) {
			setTrackingSent(true)
			logCustomEvent({ action: 'start_form_engagement', category: 'engagement', label: 'Form_Part_2' })
		}
	}, [values.recipients])

	return (
		<>
			<Card
				px={['1rem', '2rem']}
				py="2rem"
				alignItems="center"
				variant="main"
				mb="2rem"
				textAlign="center"
				mx={['1rem', 0]}
				borderRadius={['xl']}
				pos="relative"
				overflow="visible">
				{values?.recipients?.[0].firstName && values?.recipients?.[0].lastName && (
					<Box
						mx="auto"
						mb="0.5rem"
						pos="absolute"
						transform="translateX(-50%)"
						left="50%"
						top="-1rem"
						padding="0.5rem"
						borderRadius="full"
						background="white">
						<CheckExMark render={true} check={true} scale={0.2} />
					</Box>
				)}

				<VStack spacing="1rem" w="full">
					<Heading size="md" as="h2">
						Who is it for?
					</Heading>
					{/* <ControlledCheckbox
						width="auto"
						control={control}
						errors={errors}
						name="isPersonal"
						description={
							<chakra.strong>It's for myself</chakra.strong>
						}
					/> */}
					{!values.isPersonal && (
						// <chakra.fieldset w="full">
						<FieldArray
							control={control}
							errors={errors}
							name="recipients"
							remove={remove}
							insert={insert}
							append={append}
							data={fields}
						/>
						// </chakra.fieldset>
					)}
				</VStack>
			</Card>
		</>
	)
}

export default StepTwo
