import { automationTemplates } from '@vidday/data'

export const chooseTheme = (occasion) => {
	let bestFitThemeId = automationTemplates.filter((theme) => theme.default)[0].id
	switch (occasion) {
		case 'congratulations':
		case 'farewell':
		case 'thankyou':
		case 'retirement':
		case 'other':
		case 'fathersday':
			bestFitThemeId = automationTemplates.find((el) => el.slug === 'watercolor').id
			break
		case 'mothers':
		case 'wedding':
			bestFitThemeId = automationTemplates.find((el) => el.slug === 'floral').id
			break
		case 'birthday':
		case 'recognition':
			bestFitThemeId = automationTemplates.find((el) => el.slug === 'razzle-dazzle').id
			break
		case 'valentines':
		case 'anniversary':
			bestFitThemeId = automationTemplates.find((el) => el.slug === 'lots-of-love').id
			break
		case 'babyshower':
			bestFitThemeId = automationTemplates.find((el) => el.slug === 'adorable').id
			break
		case 'graduation':
			bestFitThemeId = automationTemplates.find((el) => el.slug === 'class-of-2023').id
			break
		case 'holiday':
			bestFitThemeId = automationTemplates.find((el) => el.slug === 'holiday-glow').id
			break
		case 'memorial':
			bestFitThemeId = automationTemplates.find((el) => el.slug === 'above-the-clouds').id
			break
		case 'school':
			bestFitThemeId = automationTemplates.find((el) => el.slug === 'school-spirit').id
			break
		default:
			break
	}
	return bestFitThemeId
}

export const chooseMusic = (occasion, defaultMusic) => {
	let bestFitMusicId = defaultMusic // default or url param
	if (occasion == 'memorial') bestFitMusicId = 'memories'
	return bestFitMusicId
}
