import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Container, VStack, Text, Heading } from '@chakra-ui/react'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { musicLibrary } from '@vidday/data'
import { useAuthContext } from '../../../contexts/AuthProvider'
import useFormState from '../../../hooks/useFormState'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'
import useDefaultThemeFromParams from './hooks/useDefaultThemeFromParams'
import useTracking from './hooks/useTracking'
import StartViddayForm from './Form'
import schema from './schema'
import { addTemporaryEvent, addEventAndRedirect } from '../../../../api/app/events'
import { setActivationRequiredFalse } from '../../../../api/app/auth'
import { chooseTheme, chooseMusic } from './utils'
import MD5 from 'crypto-js/md5'
import CryptoJS from 'crypto-js'
import MetaTagsDefault from '../../organisms/MetaTagsDefault'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import ModalPromoQR from '../../organisms/ModalPromoQR'
import redirectToNextIfWindow from '../../../utils/redirectToNextIfWindow'
// required plugins for dayjs.tz
dayjs.extend(utc)
dayjs.extend(timezone)

const StartVidday = () => {
	useScrollTopOnMount()
	useTracking()

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { profile, userId } = useAuthContext()
	const { data: reduxData } = useFormState('NewVidday')

	/** Form Hook */
	const methods = useForm({
		defaultValues: { ...reduxData, recipients: [{ firstName: null, lastName: null }] },
		resolver: yupResolver(schema),
		shouldFocusError: true,
	})

	/** check if a "promo_code" query param is set - no need to check it, this will happen later */
	/** check if a "promo_code" query param is set - no need to check it, this will happen later */
	const [query] = useSearchParams()
	const [promoCode, setPromoCode] = useState()
	useEffect(() => {
		if (query.has('promo_code')) {
			setPromoCode(query.get('promo_code'))
		}
	}, [])

	/** Retrieve default theme template ID - this is null when url param is not present or invalid */
	const themeTemplateId = useDefaultThemeFromParams()
	/** Retrieve default background music ID */
	const [defaultTrack] = useState(musicLibrary.filter((track) => track.default)[0].id)
	/** Retrieve default timezone from profile (if any) */
	const timezoneFromProfile = useSelector(
		(s) => s.auth.profile && s.auth.profile.location && s.auth.profile.location.timezone,
		shallowEqual
	)
	/** Define timezone automatically */
	const autoTimezone = reduxData.timezone
		? reduxData.timezone
		: timezoneFromProfile
		? timezoneFromProfile
		: dayjs.tz.guess()

	const onSubmit = (values) => {
		let data = { ...reduxData, ...values }
		console.log('Start Form Submit Data', data)

		// Set theme to use; use the occasion's default theme if theme has not yet been set
		data.themeTemplateId = themeTemplateId ? themeTemplateId : chooseTheme(data.occasion)
		// Set Playlist to contain one song, the current default Music
		data.backgroundMusicIds = [chooseMusic(data.occasion, defaultTrack)]
		// Set giftWrapEffect false for Memorial
		if (data.occasion == 'memorial') data.giftWrapColor = null
		// Set Creator as recipient on personal events
		if (data.isPersonal && profile) {
			if (data.recipients.length) {
				data.recipients[0] = { firstName: profile.firstName, lastName: profile.lastName }
			} else {
				data.recipients = [{ firstName: profile.firstName, lastName: profile.lastName }]
			}
		}
		// Enforce Recipients schema
		var recipients = data.recipients.map(function (obj) {
			return { firstName: obj.firstName, lastName: obj.lastName }
		})
		// Assign recipients
		data.recipients = recipients
		// Assign timezone
		data.timezone = autoTimezone
		// Set deadline date based off of occasion automatically
		//data.deadline = dayjs().tz(autoTimezone).add(4, 'days') // Right now plus X days
		// Set default deadline set
		data.deadlineSet = false
		// Set authed user as creatorId (if user is authed)
		data.creatorId = userId
		// Attach promo code from query params if it was present
		data.promoCode = promoCode ? promoCode : undefined
		// Create a unique hash from form data (DO THIS LAST!!!)
		data.idempotencyKey = MD5(JSON.stringify(data)).toString(CryptoJS.enc.Hex)

		// IF USER IS NOT LOGGED IN (i.e. data.creatorId is undefined)
		if (!data.creatorId) {
			// console.log('DATA start: not creator', data)
			// Save on the state
			dispatch(addTemporaryEvent(data))
			// remove activation prompt incase it's set.
			dispatch(setActivationRequiredFalse())
			// Redirect to login page (Option to register) so we can later on submit the event
			redirectToNextIfWindow(`/auth/register?occasion=${data.occasion}`)
			// navigate(`/auth/register?occasion=${data.occasion}`)
			// V1 navigate(`/start-a-vidday/complete/register?occasion=${data.occasion}`)
		} else {
			// console.log('DATA start: with creator', data)
			dispatch(addEventAndRedirect(data))
		}
	}

	return (
		<>
			<MetaTagsDefault
				title="Start a VidDay for any occasion!"
				description="VidDay, a video maker for any special occasion! Get everyone to join in on the surprise video to celebrate that special day — no app to download! Get Started."
			/>
			<ModalPromoQR />
			<FormProvider {...methods}>
				<Container py="2rem" variant="main" maxW="540px">
					<VStack mb="2rem" textAlign="center" px={['1rem', 0]}>
						<Text fontSize="3rem">🥳</Text>
						<Heading as="h2" variant="hero" size="lg">
							Great, let's start!
						</Heading>
						<Text>Tell us about the special occasion to set up an Event Page.</Text>
					</VStack>
					<StartViddayForm onSubmit={onSubmit} />
				</Container>
			</FormProvider>
		</>
	)
}

export default StartVidday
