import * as Yup from 'yup'
import { showYearsFieldFor } from '../EventDetails/schema'

/**
 * STEP 1 - Validation
 */

export const schemaStepOne = Yup.object().shape({
	occasion: Yup.string().required('Please select an occasion.'),
	occasionOther: Yup.string().when('occasion', {
		is: (occasion) => occasion !== '' && (occasion === 'other' || occasion === 'congratulations'),
		then: Yup.string().max(50, 'Maximum of 50 characters.').required('Please enter the occasion title.'),
		otherwise: Yup.string().notRequired().default(''),
	}),
	years: Yup.number().when('occasion', {
		is: (occasion) => {
			var isIncluded = showYearsFieldFor.includes(occasion)
			return isIncluded
		},
		then: Yup.number()
			.notRequired()
			.positive()
			.integer()
			.nullable(true)
			.default(null)
			.transform((v, o) => (o === '' ? null : v)),
		otherwise: Yup.number()
			.notRequired()
			.nullable(true)
			.default(null)
			.transform((v, o) => (o === '' ? null : v)),
	}),
})

/**
 * STEP 2 - Validation
 */

export const schemaStepTwo = Yup.object().shape({
	isPersonal: Yup.boolean(),
	recipients: Yup.array().when('isPersonal', {
		is: true,
		then: Yup.array().notRequired(),
		otherwise: Yup.array()
			.min(1)
			.of(
				Yup.object().shape({
					firstName: Yup.string()
						.max(30)
						.required('Tell us the first name.')
						.transform((v, o) => (o === '' ? null : v))
						.typeError('Tell us the first name.'),
					lastName: Yup.string()
						.max(35)
						.required('Tell us the last name.')
						.transform((v, o) => (o === '' ? null : v))
						.typeError('Tell us the last name.'),
					id: Yup.string().nullable().default(null),
				})
			)
			.required(),
	}),
})

/**
 * STEP 3 - Validation
 */

export const schemaStepThree = Yup.object().shape({
	occasionDate: Yup.string().notRequired().nullable(), // deprecated jan 11th 2024 - use deadline
	timezone: Yup.string().notRequired().nullable(),
})

/**
 * STEP 4 - Gift Card / Cash Collection
 */

export const schemaStepFour = Yup.object().shape({
	allowCashCollection: Yup.boolean().notRequired().default(false),
})

function merge(...schemas) {
	const [first, ...rest] = schemas

	const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first)

	return merged
}
/**
 * One Pager
 */
export default merge(schemaStepOne, schemaStepTwo, schemaStepThree) //, schemaStepFour
