import useTemporaryEvent from '../../../../hooks/useTemporaryEvent'
import { useState, useEffect } from 'react'
import { logLead } from '@vidday/utils'

/**
 * Start tracking of the form
 * */
const useTracking = () => {
	const temporaryEvent = useTemporaryEvent()
	const [trackingSent, setTrackingSent] = useState(false)

	useEffect(() => {
		// only log id there isn't a temp event and this is a fresh start
		if (!trackingSent && window && !temporaryEvent) {
			setTrackingSent(true)
			logLead()
		}
	}, [trackingSent])
}

export default useTracking
