import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { automationTemplates } from '@vidday/data'

/**
 * Determine if we want to set a default theme based on URL search params props
 * returns null when no 'theme' query param exists OR when no matching theme is found
 * returns a valid themeId if and only if it matches an entry automationTemplates
 * */
const useDefaultThemeFromParams = () => {
	const [query] = useSearchParams()
	const [themeTemplateId, setThemeTemplateId] = useState(null)

	/** Set theme based on url param */
	useEffect(() => {
		if (query.has('theme')) {
			// Retrieve index of the corresponding theme (match the theme slug here)
			const themeIndex = automationTemplates.findIndex((el) => el.slug === query.get('theme'))
			// If we found a match, update current theme to use (use the theme id here)
			if (themeIndex != -1) {
				setThemeTemplateId(automationTemplates[themeIndex].id)
			}
		}
	}, [])

	return themeTemplateId
}

export default useDefaultThemeFromParams
