import React from 'react'
import { chakra, Box, Button, HStack } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import useFormState from '../../../../hooks/useFormState'

const StartViddayForm = ({ onSubmit }) => {
	/** Retrieve form state from context */
	const { handleSubmit, watch, formState, errors } = useFormContext()

	const { status } = useFormState('NewVidday')

	/** Watch values of the form */
	const values = watch()
	return (
		<chakra.form w="full" onSubmit={handleSubmit(onSubmit)}>
			<Box w="full">
				<StepOne />
				<StepTwo />
			</Box>

			<HStack w="full" justify="center" mb="2rem">
				<Button
					type="submit"
					variant="solid"
					minW="150px"
					disabled={
						!values.occasion ||
						formState.isSubmitting ||
						status.isFetching ||
						(formState.isSubmitted && Object.keys(errors).length > 0) ||
						(!values.isPersonal &&
							values?.recipients &&
							(values?.recipients[0].firstName == '' || values?.recipients[0].lastName == ''))
					}
					isLoading={status.isFetching || formState.isSubmitting}>
					<span>Start a VidDay</span>
				</Button>
			</HStack>
		</chakra.form>
	)
}

export default StartViddayForm
