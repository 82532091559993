import React, { memo, useEffect, useState } from 'react'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	chakra,
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

const ModalPromoQR = memo(({ onClose, isOpen }) => {
	const location = useLocation()
	const param = new URLSearchParams(location.search).get('promo_code')
	/** Look for param */
	useEffect(() => {
		if (param?.includes('LANDMARK')) {
			setShowModal(true)
		}
	}, [param])

	const [showModal, setShowModal] = useState(false)

	return (
		<Modal
			onClose={() => {
				setShowModal(false)
			}}
			isOpen={showModal}
			scrollBehavior="outside"
			isCentered={true}
			size="lg"
			variant="confetti">
			<ModalOverlay bg="gray.900" opacity="0.9 !important" />
			<ModalContent m="1rem">
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image alt="Tada!" src={'/assets/images/vidday_logo.svg'} width="150px" />
						<Heading size="lg">
							Claim your limited
							<br /> time offer.
						</Heading>
						<Text fontSize="1.25rem">
							Tell us what you’re celebrating <br /> and{' '}
							<chakra.strong color="gray.900">get 50% off</chakra.strong>.
						</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<VStack w="full" spacing="1rem" justifyContent="center" position="relative">
						<Button
							size="lg"
							m="0"
							onClick={() => {
								setShowModal(false)
							}}>
							Let's Go!
						</Button>
						<Text align={'center'} lineHeight="1rem">
							<small>
								<chakra.strong color="gray.900">Start for free,</chakra.strong>
								<br />
								no credit card needed.
							</small>
						</Text>
						<chakra.svg
							sx={{ position: 'absolute', transform: 'translate(80px, 10px)' }}
							xmlns="http://www.w3.org/2000/svg"
							width="53.302"
							height="55.866"
							viewBox="0 0 53.302 55.866">
							<defs>
								<clipPath id="clip-path">
									<rect
										id="Rectangle_2230"
										data-name="Rectangle 2230"
										width="38.814"
										height="43.872"
										fill="#3c586b"
									/>
								</clipPath>
							</defs>
							<g
								id="Group_24795"
								data-name="Group 24795"
								transform="matrix(0.914, 0.407, -0.407, 0.914, 17.844, 0)">
								<g
									id="Group_24792"
									data-name="Group 24792"
									transform="translate(0)"
									clipPath="url(#clip-path)">
									<path
										id="Path_25027"
										data-name="Path 25027"
										d="M9.189,4.623C10.522,2.8,13.384,2.871,13.777,0a7.331,7.331,0,0,0-1.344.312c-3.671,1.75-7.345,3.5-10.982,5.317-.6.3-1.367.9-1.439,1.447C-.12,8.077.841,8.324,1.7,8.5c2.673.547,5.338,1.128,8.025,1.7-.013-1.643-.013-1.643-4.344-3.243C13.4,4.187,21.413,8.911,22.461,17.13c-.821.06-1.64.093-2.453.182A9.2,9.2,0,0,0,13.893,20.3a4.19,4.19,0,0,0-1.029,4.587c.7,1.76,2.244,2.332,4.009,2.44a7.08,7.08,0,0,0,7.67-5.657c.116-.463.306-.907.517-1.519,4.55,1.688,8.156,4.383,10.368,8.592,3.409,6.49-.427,11.536-4.946,13.984-.558.3-1.173.5-2.126.9a4.729,4.729,0,0,0,3.649-.3,11.274,11.274,0,0,0,6.8-10.653,12.729,12.729,0,0,0-2.926-7.534,21.338,21.338,0,0,0-9.8-6.979c-.39-.132-.772-.289-1.285-.481-.736-3.756-1.927-7.319-5.037-9.934C16.715,5.19,12.944,5.1,9.189,4.623m13.484,14.91c-.009,3.95-2.406,6.391-5.77,5.943a3.464,3.464,0,0,1-2.194-1.294c-.621-.919-.033-1.872.738-2.615,2-1.926,4.39-2.4,7.227-2.034"
										transform="translate(0 0)"
										fill="#3c586b"
									/>
								</g>
							</g>
						</chakra.svg>
					</VStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
})

export default ModalPromoQR
